const entrance = {
  'pages.entrance.organization.welcome': '欢迎来到睿安通，目前您还没有组织，请选择创建或者加入组织',
  'pages.entrance.organization.join': '加入组织',
  'pages.entrance.organization.joinTip':
    '通过组织代码查询已存在的组织，等待组织管理员审核通过后即可加入',
  'pages.entrance.organization.create': '创建组织',
  'pages.entrance.organization.createTip':
    '创建一个全新的组织，可用于企业、组织、团队或者个人，可邀请同事一起协作',
  'pages.entrance.organization.code': '组织代码',
  'pages.entrance.organization.status': '组织状态：',
  'pages.entrance.organization.codes': '组织代码：',
  'pages.entrance.organization.field.inviteCode': '组织代码',
  'pages.entrance.organization.field.inviteCodeHint':
    '请输入组织代码-组织代码在组织管理详情页面中查看',
  'pages.entrance.organization.field.applyRemark': '申请备注',
  'pages.entrance.organization.field.applyRemarkHint': '请输入备注说明',
  'pages.entrance.organization.field.name': '组织名称',
  'pages.entrance.organization.field.nameHint': '请输入组织名称',
  'pages.entrance.organization.field.nameTip':
    '创建一个全新的组织,组织名称支持后续在组织管理页面中进行修改',
  'pages.entrance.organization.action.join': '立即加入',
  'pages.entrance.organization.action.create': '立即创建',
  'pages.entrance.organization.phone': '手机号',
  'pages.entrance.organization.account': '账号',
  'pages.entrance.organization.verification.code': '验证码',
  'pages.entrance.organization.password': '密码',
  'pages.entrance.organization.nickname': '昵称',
  'pages.entrance.organization.new.password': '请再次输入密码',
  'pages.entrance.organization.different.password': '两次密码不一致',
  'pages.entrance.organization.checkAgree': '请阅读并同意 服务条款、隐私政策,并勾选',
  'pages.entrance.organization.remark': '申请备注',
  'pages.entrance.organization.startTEXT': '请按住滑块，拖动到最右边',
  'pages.entrance.organization.yesTEXT': '验证通过',
  'pages.entrance.organization.error': `<a href="javascript:__nc.reset()">刷新</a>再来一次`,
  'pages.entrance.organization.errorNetwork':
    '网络不给力，请<a href="javascript:__nc.reset()">点击刷新</a>',
  'pages.entrance.organization.agree': '同意',
  'pages.entrance.organization.invite.join': '邀请您加入:',
  'pages.entrance.organization.work.together': ' 一起开启全新协同办公体验',
  'pages.entrance.organization.work.together.supplier': ' 一起开启全新协同供应体验',
  'pages.entrance.organization.automatic.login': '3天内自动登录',
  'pages.entrance.organization.read.agree': '阅读并同意',
  'pages.entrance.organization.terms.service': '服务条款',
  'pages.entrance.organization.privacy.policy': '隐私政策',
  'pages.entrance.organization.gain.verification.code': '获取验证码',
  'pages.entrance.organization.reacquire': '后重新获取',
  'pages.entrance.organization.login': '登录',
  'pages.entrance.organization.supplier.signup': '供应商注册',
  'pages.entrance.organization.supplier.refound': '企业找回',
  'pages.entrance.organization.WeChat': '通过微信扫一扫 或',
  'pages.entrance.organization.verification.code.login': '验证码登录',
  'pages.entrance.organization.quick.account.create': '快速创建账号',
  'pages.entrance.organization.forget.password': '忘记密码',
  'pages.entrance.organization.dispose': '有组织邀请您加入，请处理',
  'pages.entrance.organization.principal.verification': '主体认证',
  'pages.entrance.organization.invite.time': '邀请时间',
  'pages.entrance.organization.click.here': '点击此处',
  'pages.entrance.organization.return.organization.choice.page': '返回选择组织页面',
  'pages.entrance.organization.reset.passwords': '重置密码',
  'pages.entrance.organization.set.new.passwords': '设置新密码(8-20个字,包含字母和数字)',
  'pages.entrance.organization.confirm.passwords': '确认密码(8-20个字,包含字母和数字)',
  'pages.entrance.organization.confirm.update': '确认修改',
  'pages.entrance.organization.already.have.account': '已有账号?',
  'pages.entrance.organization.set.login.account.password': '设置登录账号密码',
  'pages.entrance.organization.login.account': '登录账号',
  'pages.entrance.organization.set.login.account.password.hint.one':
    '设置账号密码以便于后续通过账号密码登录，您可以',
  'pages.entrance.organization.set.login.account.password.hint.two': '跳过本操作',
  'pages.entrance.organization.set.login.account.password.hint.three':
    '，后续在设置-个人设置中修改',
  'pages.entrance.organization.bind.phone': '绑定手机号',
  'pages.entrance.organization.bind.phone.hint':
    '为了账户安全,建议您绑定一个常用手机号，后续也可以在设置-个人设置中更换手机号',

  'pages.entrance.cris.hint': '睿安通管理平台',
}
export default entrance
